<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3C7.44772 3 7 3.44772 7 4V5.5C7 6.05228 6.55228 6.5 6 6.5C5.44772 6.5 5 6.05228 5 5.5V4C5 2.34315 6.34315 1 8 1H20C21.6569 1 23 2.34315 23 4V16C23 17.6569 21.6569 19 20 19H18C17.4477 19 17 18.5523 17 18C17 17.4477 17.4477 17 18 17H20C20.5523 17 21 16.5523 21 16V4C21 3.44771 20.5523 3 20 3H8Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7C3.44772 7 3 7.44772 3 8V20C3 20.5523 3.44772 21 4 21H16C16.5523 21 17 20.5523 17 20V8C17 7.44772 16.5523 7 16 7H4ZM1 8C1 6.34315 2.34315 5 4 5H16C17.6569 5 19 6.34315 19 8V20C19 21.6569 17.6569 23 16 23H4C2.34315 23 1 21.6569 1 20V8Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 12.2929C6.68342 11.9024 7.31658 11.9024 7.70711 12.2929L10 14.5858L12.2929 12.2929C12.6834 11.9024 13.3166 11.9024 13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L6.29289 13.7071C5.90237 13.3166 5.90237 12.6834 6.29289 12.2929Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconCopy'
}
</script>